import React from 'react';
import PropTypes from 'prop-types';
import { FitnessChampionsContainer, ImageContainer, UserInterest } from '../FitnessTabV2/styles';
import { Button } from './styles';
import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
import LazyImage from '../common/LazyImage/LazyImage';
import { ImageUrl } from '../../utils/constants';

class UserCard extends React.Component {
  fallBack = (e) => {
    e.target.src = '/public/images/neutral_avatar.svg'
  }
  render() {
    const { data } = this.props;
    return _.isUndefined(data) || _.isNull(data) || isEmpty(data) ? <FitnessChampionsContainer>No User Card Data</FitnessChampionsContainer> : <FitnessChampionsContainer>
      <div>
        {data && data.is_champion ? <div className="champion-tag">
          <p>Wellness Champions</p>
        </div> : data && data.is_wellness_admin ? <div className="champion-tag">
          <p>Wellness Admins</p>
        </div> : null}
        <div>
          <LazyImage div={"image"} onError={this.fallBack} src={ImageUrl + "/" + data.profile_image} alt="no-image" />
        </div>
        <div className="name">
          <div>{data.name}</div>
        </div>
        <div className="designation">
          {data.department || "NA"}
        </div>
        <UserInterest>
          {data && data.interests.slice(0, 5).map((item, index) => (
            <ImageContainer key={index}>
              <LazyImage src={ImageUrl + "/" + item.interest_icon} alt="no-image" />
            </ImageContainer>
          ))}
        </UserInterest>
        <div className='wellness-pts'>
          <span className='title'> Wellness Points : </span> <span className='pts'>{`${data  && data.total_wellness_point ? data.total_wellness_point : "NA"}`}</span>
        </div> 
        <div className="state-city">
          {data.location}
        </div>
        <div className="button">
          <span>
            <Button backgroundColor="white" color="rgb(153,153,153)">ADD</Button>
          </span>
          <span className="text">
            <Button>Message</Button>
          </span>
        </div>
      </div>
    </FitnessChampionsContainer>;
  }
}

UserCard.propTypes = {
  data: PropTypes.object
};

export default UserCard;
