/* eslint-disable no-console, no-undef, camelcase */
import React, { Component } from 'react';
//import { Modal } from 'react-bootstrap';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import {getUserEmailNotifications,putUpdateEmailsNotifications } from '../../redux/actions';
import { DeviceContainer, LogoWrapper, CustomLabel, CustomCheckbox, Slider,
  RolesContainer, NameContainer  } from '../ConnectDeviceModal/styles';
import { withTranslation } from 'react-i18next';
  
class UserEmailNotifications extends Component {
  constructor(){
    super();
    this.state = {
      emailNotification: []
    }  
  }
  updateEmailNotificationStatus = (key) => {
    const { emailNotification } = this.state
    const { putUpdateEmailsNotifications } = this.props
    let obj = {
      "event_email": 0,
      "challenge_email": 0,
      "newsletter_email": 0
    };
    emailNotification.forEach((data) => {
      if(data.key !== "id"){
        obj[data.key] = key === data.key ? data.status === 0 ? 1 : 0  : data.status;
      }
    })
    putUpdateEmailsNotifications(obj);
  };

  componentDidMount () 
  {
    const { getUserEmailNotifications} = this.props;
    getUserEmailNotifications();

  }
  componentDidUpdate(prevProps){
    if(prevProps.userEmailNotifications !== this.props.userEmailNotifications){
      this.setState({emailNotification: this.props.userEmailNotifications})
    }
  }
  render() {
    const { emailNotification } = this.state;
    const { t } = this.props;
    return (
      <RolesContainer>
        {
          emailNotification && emailNotification.length > 0 ? emailNotification.map((notification, index) => (
            <RolesContainer key={index}>
              <NameContainer width={'70%'}>
                <LogoWrapper>
                  <div>{t(notification.title)}</div>
                </LogoWrapper>
              </NameContainer>
              <NameContainer width={'30%'}>
                {
                  <CustomLabel className="switch">
                    <CustomCheckbox
                      type="checkbox"
                      checked={notification.status}
                      onChange={() => this.updateEmailNotificationStatus(notification.key, notification.status)}
                    />
                    <Slider className="slider round" />
                  </CustomLabel>
                }
              </NameContainer>
            </RolesContainer>
          )) : <DeviceContainer><div><LogoWrapper><div>{t("No Data")}</div></LogoWrapper></div></DeviceContainer>
        }
      </RolesContainer>
    )
  }
}

UserEmailNotifications.propTypes = {

  getUserEmailNotifications: PropTypes.func,
  userEmailNotifications: PropTypes.array,
  putUpdateEmailsNotifications: PropTypes.func,
  t: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
  getUserEmailNotifications: () => dispatch(getUserEmailNotifications()),
  putUpdateEmailsNotifications: (obj) => dispatch(putUpdateEmailsNotifications(obj))
});

const mapStateToProps = (state) => ({
  userEmailNotifications : state.userNotification.userEmailNotifications,
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserEmailNotifications));
