import styled from 'styled-components';

const BoldFont = 'Rubik-Bold';

const Button = styled.button`
  height: 24px;
  border-radius: 12px;
  font-size: 10px;
  background-color: ${({ backgroundColor }) => backgroundColor ? backgroundColor : '#159fc9'};
  color: ${({ color }) => color ? color : 'white'};
  padding: 0 11px;
  border: ${({ backgroundColor }) => backgroundColor ? '1px solid #dad9d9' : '1px solid #159fc9'};
  font-family: ${BoldFont};
  text-transform: uppercase;

  &:focus, &:hover, &:active {
    outline: none;
  }
`;

const FitnessChampionsContainer = styled.div`
  //border: 1px solid #e9e8e8;
  background-color: #fff;
  border-radius: 6px;
  text-align: center;
  //min-height: 332px;
  width: 100%;
   font-size: 12px;
  //display: flex;
  //justify-content: center;
  //align-items: center;
  position: relative;
  height: 100%;

   //width: 300px;
   //height: 232px;
  
  .champion-tag {
    //width: 300px;
   height: 74px;
   background: #0D4270;
   border-radius: 6px 6px 0px 0px;    
  border-radius: 6px 6px 0px 0px;    
   border-radius: 6px 6px 0px 0px;    
  border-radius: 6px 6px 0px 0px;    
   border-radius: 6px 6px 0px 0px;    
    > p {
    font-family: Rubik;
    font-weight: 500;
    font-size: 16px;
    //line-height: 24px;
    text-transform: capitalize;
    text-align: center;
    color: #FFFFFF;
    //background: #1E76AB;
    margin: 0;
    border-radius: 6px 6px 0px 0px;
    letter-spacing: 1px;
    position: relative;
    bottom: 5px;
    }
   

  }
  
  > div{
    width: 100%;
    height: 100%;
    > div {
       //padding: 10px;
      padding-top: 15px;
      // margin-bottom: -35px;
      display: flex;
      align-items: flex-start;
      .image {
         display: flex;
        //  height: 115px;
        > img {
          height: 115px;
          border: 3px solid #FFFFFF;
          width: 80px;
          border-radius: 6px;
          object-fit: cover;
          background-color: white;
        }
      }
      .details{
        margin-left: 15px;
        text-align: left;
      .name {
        font-family: Rubik-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        text-align: left;
        color: #3A3A3A;
        text-transform: capitalize;
        padding-top: 20px;
      }
      
      .designation {
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        text-align: left;
        color: #9C9C9C; 
        text-transform: capitalize;
      }
      .state-city {
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        text-align: left;
        color: #9C9C9C; 
        text-transform: capitalize;
      }
    }
      .company {
        font-weight: normal;
        text-transform: capitalize;
        color: rgb(153,153,153);
        font-size: 12px;
      }
    }
    .button {
      margin-top: 20px;
      display: none;
      > span{
        > div {
          display: inline-block;
        }
      }
      .text {
        margin-left: 10px;
      }
    }
  } 
  .color-band {
    font-family: ${BoldFont};
    font-weight: 700;
    font-size: 17px;
    line-height: 24px;
    text-align: center;
    color: #A5ABBA;
    text-transform: none;
    height: 44px;

  }
`;

const ImageContainer = styled.div`
    
    display: flex;
    width: 40px;
    height: 40px;
    //background-color: #0D4270;
    background: linear-gradient(360deg, #1E6481 0%, #0D425C 60.94%, #022C43 100%);
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    margin: 0px 3px;
    margin-top: 10px;
;
     > img {
      width: 25px !important;
      height: 25px !important;
     }
`;

const UserInterest = styled.div`
    width: 100%;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-wrap: wrap;
    padding: 0 3px !important;
    margin: 0 auto;
    min-height: 44px;
    position: relative;
    bottom: 10px;
`;


export { Button,FitnessChampionsContainer,ImageContainer,UserInterest};


