import styled from 'styled-components';

const BoldFont = 'Rubik-Bold';

const Button = styled.button`
  height: 24px;
  border-radius: 12px;
  font-size: 10px;
  background-color: ${({ backgroundColor }) => backgroundColor ? backgroundColor : '#159fc9'};
  color: ${({ color }) => color ? color : 'white'};
  padding: 0 11px;
  border: ${({ backgroundColor }) => backgroundColor ? '1px solid #dad9d9' : '1px solid #159fc9'};
  font-family: ${BoldFont};
  text-transform: uppercase;

  &:focus, &:hover, &:active {
    outline: none;
  }
`;

export { Button };
