import React from 'react';
import PropTypes from 'prop-types';

//import { ChampionsContainer } from '../EngagementSection/styles';
import { /*Button,*/FitnessChampionsContainer,/*ImageContainer,UserInteres*/ } from './styles';
import _ from 'lodash';
// import Image from '../Image';
import isEmpty from 'lodash/isEmpty';
import { ImageUrl } from '../../utils/constants';
import LazyImage from '../common/LazyImage/LazyImage';

class UserCard extends React.Component {

  fallBack = (e) => {
    e.target.src = '/public/images/neutral_avatar.svg'
  }

  render() {
    const { data } = this.props;
    if (_.isUndefined(data) || _.isNull(data) || isEmpty(data)) {
      return  <FitnessChampionsContainer>No User Card Data</FitnessChampionsContainer>
    }
    else {
      return <FitnessChampionsContainer>
        <div>
          {/* {data && data.is_champion ? <div className="champion-tag">
            <p>Wellness Champions</p>
          </div> : data && data.is_wellness_admin ? <div className="champion-tag">
            <p>Wellness Admins</p>
          </div> : null} */}
          <div>
            <LazyImage div={"image"} onError={this.fallBack} src={`${ImageUrl}/${data.profile_image}`} alt="no-data" />
            <div className={'details'}>
              <div className="name">{data.name}</div>
              <div className="designation">
                {data.department}
              </div>
              <div className= "state-city">
                {data.location}
              </div>
            </div>
          </div>
          {/* <div className="name">
            <div>{data.name}</div>
          </div> */}
          {/* <div className="designation">
            {data.department}
          </div>
          <div className= "state-city">
            {data.location}
          </div> */}
          {/* <UserInterest>
            {data && data.interests.slice(0, 5).map((item, index)=>(
              <ImageContainer key={index}>
                <Image image={item.interest_icon} alt="no-image" />
              </ImageContainer>
            ))}
          </UserInterest> */}
          {/* <div className="color-band">
            {data.reward_tier}
          </div> */}
          {/* <div className= "state-city">
            {data.location}
          </div>
          <div className="button">
            <span>
              <Button backgroundColor="white" color="rgb(153,153,153)">ADD</Button>
            </span>
            <span className="text">
              <Button>Message</Button>
            </span>
          </div> */}
        </div>
      </FitnessChampionsContainer>
    }
  }
}

UserCard.propTypes = {
  data: PropTypes.object
};

export default UserCard;